import type { ReactElement, ReactNode } from 'react'
import { createContext, forwardRef, useContext, useEffect, useId, useLayoutEffect, useRef, useState } from 'react'

import { FocusScope, useFocusManager } from '@react-aria/focus'
import { useOverlay } from '@react-aria/overlays'

import { SlotProvider } from '~/utils/components/slots'
import { useComposeRefs } from '~/utils/use-compose-refs'
import { usePreventScroll } from '~/utils/use-prevent-scroll'

import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

const InsideDialogContext = createContext(false)

export const useInsideDialog = () => useContext(InsideDialogContext)

export type Props = {
    children: ReactNode
    onClose?: (event: CustomEvent<unknown>) => void
    isDismissable?: boolean
    contain?: boolean
    autoFocus?: boolean
    preventScroll?: boolean
    shouldCloseOnBlur?: boolean
    isKeyboardDismissDisabled?: boolean
    isOutsideDismissable?: boolean
    role?: 'dialog' | 'alertdialog'
    mode?: 'dialog' | 'popover'
} & Omit<BoxProps, 'role'>

const focusOrder = [
    (node) => (node as HTMLElement).dataset.autoFocus === 'true',
    (node) => node.tagName === 'INPUT' && (node as HTMLElement).tabIndex !== -1,
    (node) => node.getAttribute('aria-label') === 'Close',
] satisfies Array<(node: Element) => boolean>

const AutoFocusManager = ({ autoFocus, children }: { autoFocus: boolean; children: ReactElement }) => {
    const focusManager = useFocusManager()

    useEffect(() => {
        if (autoFocus) {
            for (const order of focusOrder) {
                const focusedElement = focusManager?.focusFirst({
                    accept: order,
                })

                if (focusedElement) {
                    return
                }
            }
        }
    }, [autoFocus, focusManager])

    return children
}

export const DialogInternal = forwardRef<HTMLElement, Props>(
    (
        {
            children,
            onClose,
            isDismissable = true,
            contain,
            autoFocus,
            preventScroll,
            shouldCloseOnBlur,
            isOutsideDismissable = true,
            role = 'dialog',
            mode,
            isKeyboardDismissDisabled = false,
            ...props
        },
        ref,
    ) => {
        const dialogRef = useRef<HTMLElement>(null)
        const [show, setShow] = useState(false)
        const handleOnClick = () => {
            const event = new CustomEvent('overlayMouseClick', {
                cancelable: true,
            })

            onClose?.(event)
        }

        const { overlayProps } = useOverlay(
            {
                isOpen: true,
                onClose: handleOnClick,
                isDismissable,
                shouldCloseOnBlur,
                isKeyboardDismissDisabled,
                shouldCloseOnInteractOutside(target: Element) {
                    // For Drawers we want to close them only when clicking on the backdrop.
                    // This is due to drawers potentially having multiple interactive elements or evoking a toasts which would be considered as outside interaction.
                    if (!isOutsideDismissable) {
                        if ((target as HTMLElement).dataset.backdrop) {
                            return true
                        }

                        return false
                    }

                    // DialogInternal is used for Dialogs and Popovers
                    // For Popovers, we need to return false here to not suppress the event, so that the
                    // outsidePress event handler of floating-ui can still receive it
                    return mode !== 'popover'
                },
            },
            dialogRef,
        )

        const id = useId()
        const titleId = props['aria-label'] ? undefined : id

        usePreventScroll({ targetRef: dialogRef, enabled: !!preventScroll })

        // Render children on the second render of this component to prevent useLayoutEffect race-conditions
        useLayoutEffect(() => setShow(true), [setShow])

        // We need to initially set autoFocus to false, so that hook inside of the FocusScope sees the
        // changes in the children.

        return (
            <InsideDialogContext.Provider value>
                <FocusScope contain={contain} restoreFocus>
                    <AutoFocusManager autoFocus={show && Boolean(autoFocus)}>
                        <Box
                            ref={useComposeRefs(dialogRef, ref)}
                            position="fixed"
                            backgroundColor="background"
                            borderWidth="none"
                            borderRadius="large"
                            boxShadow="medium"
                            color="text"
                            {...props}
                            {...overlayProps}
                            onKeyDown={(event) => {
                                overlayProps.onKeyDown?.(event)
                                props.onKeyDown?.(event)
                            }}
                            onFocus={(event) => {
                                overlayProps.onFocus?.(event)
                                props.onFocus?.(event)
                            }}
                            onBlur={(event) => {
                                overlayProps.onBlur?.(event)
                                props.onBlur?.(event)
                            }}
                            role={role}
                            tabIndex={-1}
                            aria-labelledby={props['aria-labelledby'] ?? titleId}
                        >
                            {show && (
                                <SlotProvider
                                    slots={{
                                        header: {
                                            id: titleId,
                                        },
                                    }}
                                >
                                    {children}
                                </SlotProvider>
                            )}
                        </Box>
                    </AutoFocusManager>
                </FocusScope>
            </InsideDialogContext.Provider>
        )
    },
)

DialogInternal.displayName = 'DialogInternal'
