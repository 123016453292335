import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconInfo = forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M13 17h-2v-6h2zm.5-9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
            clipRule="evenodd"
        />
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
            clipRule="evenodd"
        />
    </Icon>
));
IconInfo.displayName = "IconInfo";
