import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { GlobalStyles } from './global-styles'

type ComponentStateContextProps = {
    disableAnimations?: boolean
}

type ComponentStateProviderProps = ComponentStateContextProps & {
    children: ReactNode
}

const ComponentStateContext = createContext<ComponentStateContextProps>({
    disableAnimations: false,
})

export const useComponentState = () => useContext(ComponentStateContext)

export const ComponentStateProvider = ({ disableAnimations, children }: ComponentStateProviderProps) => {
    const contextValue = useMemo(
        () => ({
            disableAnimations,
        }),
        [disableAnimations],
    )

    return (
        <ComponentStateContext.Provider value={contextValue}>
            <GlobalStyles disableAnimations={disableAnimations} />
            {children}
        </ComponentStateContext.Provider>
    )
}
