import type { ComponentProps, ReactElement, ReactNode } from 'react'
import { createContext, useContext } from 'react'

import { doNothing } from 'remeda'

import type { OptionalProps } from '../../types'
import type { Drawer } from '../drawer/drawer'
import type { AlertDialogProps } from '../modal-dialog/alert-dialog'
import type { ConfirmDialogProps } from '../modal-dialog/confirm-dialog'
import type { DeleteDialogProps } from '../modal-dialog/delete-dialog'
import type { ModalDialogProps as BaseModalDialogProps } from '../modal-dialog/modal-dialog'

type ModalDialogProps = Omit<BaseModalDialogProps, 'children'> & {
    body: ReactNode
}

type DrawerProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    body: ReactNode
}

export type DialogHookProps = OptionalProps<ModalDialogProps, 'onClose'>
export type DrawerHookProps = OptionalProps<DrawerProps, 'onClose'>

export type DialogContextProps = {
    open: (modalDialogProps: DialogHookProps | ReactElement) => void
    closeDialog: () => void
    closeDrawer: () => void
    confirm: (props: OptionalProps<ConfirmDialogProps, 'onClose'>) => Promise<boolean>
    alert: (props: OptionalProps<AlertDialogProps, 'onClose'>) => void
    confirmDelete: (props: OptionalProps<DeleteDialogProps, 'onClose'>) => Promise<boolean>
    drawer: (props: DrawerHookProps | ReactElement) => void
}

const DialogContext = createContext<DialogContextProps>({
    open: doNothing,
    closeDialog: doNothing,
    closeDrawer: doNothing,
    confirm: () => Promise.resolve(false),
    alert: doNothing,
    confirmDelete: () => Promise.resolve(false),
    drawer: doNothing,
})

export const useInternalDialog = () => useContext(DialogContext)

export const DialogContextProvider = DialogContext.Provider
