import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconCheck = forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="m18.556 8.657-1.414-1.414-7.485 7.485-3.243-3.243L5 12.9l3.243 3.242 1.414 1.414z"
            clipRule="evenodd"
        />
    </Icon>
));
IconCheck.displayName = "IconCheck";
