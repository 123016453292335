import type { ComponentPropsWithRef, KeyboardEvent, MouseEvent } from 'react'
import { forwardRef, useState } from 'react'

import { IconCheck } from '~/icons/check'
import { IconClose } from '~/icons/close'
import { setAlpha } from '~/utils/color'
import { getFocusRing } from '~/utils/focus'
import { useTheme } from '~/utils/use-theme'

import type { Size, Variant } from '../../types'
import { BorderBox } from '../border-box/border-box'
import { Box } from '../box/box'
import { useFieldContext } from '../field/context'
import { getInfoTipByProps, useInfoTipWrapperId } from '../info-tip/utils'
import type { InfoTipProp } from '../info-tip/utils'
import { Stack } from '../stack/stack'

const labelSizeMap = {
    'x-small': 'small',
    small: 'small',
    medium: 'medium',
    large: 'large',
    'x-large': 'large',
} as const

export const useSwitchState = (initialState: { state?: boolean } = {}) => {
    const [state, setState] = useState(initialState.state)

    return { state, setState }
}

type Props = Omit<ComponentPropsWithRef<typeof BorderBox>, 'role'> &
    Partial<ReturnType<typeof useSwitchState>> & {
        /**
         * The size of the component. Check out the <a href="/?path=/docs/foundation-spacing--page">sizing article</a> in the Styleguide.
         */
        size?: Size
        /**
         * Specifies the color of the component.
         */
        variant?: Variant
        /**
         * adds a label to the left of the switch component
         */
        label?: string
        /**
         * renders the component with 100% width and space between
         */
        fullWidth?: boolean
        /**
         * The content of the `InfoTip` or the object that contains the props of it.
         */
        infoTip?: InfoTipProp
    }

export const Switch = forwardRef<HTMLOutputElement, Props>(
    // eslint-disable-next-line complexity
    ({ size = 'medium', id, infoTip, label, fullWidth, css, ...props }, ref) => {
        const theme = useTheme()
        const field = useFieldContext()
        const elementId = useInfoTipWrapperId(id, label)

        const actualSize = theme.space[size === 'x-small' ? 'small' : size]
        const {
            disabled = field.disabled,
            variant = field.variant,
            state,
            checked: checkedProp,
            setState,
            onChange,
            onClick,
            ...rest
        } = props
        const palette = variant ?? 'brand'
        const checked = checkedProp ?? state ?? false
        const clickHandler = (event: MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            event.preventDefault()
            onChange?.(event)
            onClick?.(event)
            setState?.(!checked)
        }
        const keyboardHandler = (event: KeyboardEvent<HTMLElement>) => {
            if (event.key === ' ' || event.key === 'Enter') {
                event.stopPropagation()
                event.preventDefault()
                onChange?.(event)
                setState?.(!checked)
            }
        }

        const behaviours = {
            disabled: {
                pointerEvents: 'none',
                cursor: 'not-allowed',
                '& > *': {
                    backgroundColor: 'textMuted',
                },
            },
            checked: {
                '& > *': {
                    backgroundColor: `${palette}Active`,
                    transform: `translateX(${actualSize})`,

                    '& > svg:nth-child(1)': {
                        visibility: 'hidden',
                    },
                    '& > svg:nth-child(2)': {
                        visibility: 'visible',
                    },
                },
            },
            hover: {
                '&:not([aria-disabled="true"])': {
                    '&[aria-checked="true"]': {
                        '& > *': {
                            backgroundColor: `${palette}Highlighted`,
                        },
                    },
                    '& > *': {
                        backgroundColor: 'text',
                    },
                },
            },
        } as const

        const focusStyle = {
            outline: 'none',
            '&[aria-checked="true"]': {
                '& > *': {
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string
                    boxShadow: `0 0 0 4px ${setAlpha(theme.colors[palette], 0.4)}`,
                },
            },
            '& > *': {
                outline: 'none',
                borderColor: `${variant}Active`,
                borderRadius: 'round',
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                boxShadow: `0 0 0 4px ${setAlpha(theme.colors.textMuted, 0.4)}`,
            },
        }

        const knobStyle = {
            position: 'absolute',
            width: '100%',
            lineHeight: '100%',
            boxSizing: 'content-box',
            left: '0',
            right: '0',
        } as const

        const switchComponent = (
            <BorderBox
                ref={ref}
                id={elementId}
                as="output"
                role="checkbox"
                aria-checked={checked}
                tabIndex={0}
                {...(disabled && { 'aria-disabled': true })}
                disabled={disabled}
                onClick={clickHandler}
                onKeyDown={keyboardHandler}
                backgroundColor="backgroundEmphasis"
                borderColor="transparent"
                borderRadius="round"
                borderWidth="none"
                display="inline-flex"
                height={actualSize}
                css={[
                    {
                        cursor: 'pointer',
                        width: `calc(${actualSize} * 2)`,
                        minWidth: `calc(${actualSize} * 2)`,
                        '&[aria-checked="true"]': behaviours.checked,
                        '&[aria-disabled="true"]': behaviours.disabled,
                        ...getFocusRing(focusStyle),
                        '&:hover': behaviours.hover,
                        '& > *': {
                            transform: 'none',
                            transition: `transform ${theme.duration.fast}`,
                        },
                    },
                    css,
                ]}
                {...rest}
            >
                <BorderBox
                    aria-hidden
                    backgroundColor="textSecondary"
                    borderColor="transparent"
                    borderRadius="round"
                    width={actualSize}
                    height={actualSize}
                    position="relative"
                >
                    <>
                        <IconClose color="background" height="auto" css={knobStyle} />
                        <IconCheck color="background" height="auto" css={{ ...knobStyle, visibility: 'hidden' }} />
                    </>
                </BorderBox>
            </BorderBox>
        )

        if (!label && !infoTip) {
            return switchComponent
        }

        const infoTipAlignmentProps = infoTip ? ({ alignItems: 'center' } as const) : {}

        return (
            <Stack
                orientation="horizontal"
                {...(fullWidth && {
                    justifyContent: 'space-between',
                    width: '100%',
                })}
                spacing={size === 'x-large' ? 'small' : 'x-small'}
                {...infoTipAlignmentProps}
            >
                <Stack
                    orientation="horizontal"
                    spacing={size === 'x-large' ? 'small' : 'xx-small'}
                    {...infoTipAlignmentProps}
                >
                    {label && (
                        <Box
                            as="label"
                            htmlFor={elementId}
                            css={{
                                cursor: 'pointer',
                                fontSize: labelSizeMap[size],
                                lineHeight: labelSizeMap[size],
                            }}
                            paddingY={size === 'x-large' ? 'xx-small' : undefined}
                            color={disabled ? 'textMuted' : undefined}
                        >
                            {label}
                        </Box>
                    )}
                    {infoTip && getInfoTipByProps({ props: infoTip, isWrapperComponentDisabled: disabled })}
                </Stack>
                {switchComponent}
            </Stack>
        )
    },
)

Switch.displayName = 'Switch'
