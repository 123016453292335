import type { ComponentProps, ReactNode } from 'react'

import { ComponentStateProvider } from '~/component-state-provider'
import { DialogProvider } from '~/components/dialog-provider/dialog-provider'
import type { LocaleValue } from '~/components/locale-provider/locale-provider'
import { LocaleProvider } from '~/components/locale-provider/locale-provider'
import { ToastProvider } from '~/components/toast/toast-provider'
import { ThemeProvider } from '~/theme-provider'

type Props = {
    children: ReactNode
    locale?: LocaleValue['locale']
    weekStartsOn?: LocaleValue['weekStartsOn']
    disableAnimations?: boolean
    dialogProviderWrapper?: ComponentProps<typeof DialogProvider>['wrapper']
    theme?: ComponentProps<typeof ThemeProvider>['theme']
    themeProvideWrapperProps?: ComponentProps<typeof ThemeProvider>['wrapperProps']
    renderToastProvider?: boolean
}

export const BaseProvider = ({
    children,
    locale,
    weekStartsOn,
    disableAnimations = false,
    dialogProviderWrapper,
    theme,
    themeProvideWrapperProps,
    renderToastProvider = true,
}: Props) => (
    <ThemeProvider wrapperProps={themeProvideWrapperProps} theme={theme}>
        <ComponentStateProvider disableAnimations={disableAnimations}>
            <LocaleProvider locale={locale} weekStartsOn={weekStartsOn}>
                <DialogProvider wrapper={dialogProviderWrapper}>
                    {renderToastProvider && <ToastProvider />}
                    {children}
                </DialogProvider>
            </LocaleProvider>
        </ComponentStateProvider>
    </ThemeProvider>
)
