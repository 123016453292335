import { isNullish } from 'remeda'

/**
 *
 * @param value any CSS unit like 10px, 20em and other values (numbers passed are treated like pixels)
 * @param defaultReturn default return in case parsing fails, represented in pixels
 * @returns parsed value represented in pixels
 */
export const parseCSSValueToNumber = (value: string | number | undefined, defaultReturn = 0): number => {
    if (typeof value === 'number') {
        return value
    }

    if (isNullish(value)) {
        return defaultReturn
    }

    // TODO: Fix this, as we provide users ability to specify values as
    // strings with concrete units (`em`, `rem`, etc) that should be
    // somehow evaluated in the context of calling component
    // https://github.com/pmedianetwork/design-system/issues/2759
    const parsed = Number.parseFloat(value)

    if (!Number.isNaN(parsed)) {
        return parsed
    }

    return defaultReturn
}
