import { forwardRef } from 'react'

import { keyframes } from 'emotion'

import { useSlotProps } from '~/utils/components/slots'
import { parseCSSValueToNumber } from '~/utils/parse-cssvalue-to-number'
import { useTheme } from '~/utils/use-theme'

import type { SpaceProps } from '../../system/styles/space'
import { isColorToken } from '../../theme/tokens/colors'
import type { CSSProp, Size, Variant } from '../../types'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'
import { iconSizeMap } from '../icons/shared'

const indeterminate = {
    '0%': { transform: 'rotateZ(0)' },
    '100%': { transform: 'rotate(360deg)' },
}

const defaultPadding = {
    paddingLeft: '2px',
    paddingRight: '2px',
    paddingTop: '2px',
    paddingBottom: '2px',
} as const

const strokeWidthMap: Record<Size, Size> = {
    'x-small': 'small',
    small: 'x-small',
    medium: 'x-small',
    large: 'x-small',
    'x-large': 'x-small',
} as const

type Props = Pick<BoxProps, 'backgroundColor' | 'color' | 'role'> &
    SpaceProps & {
        variant?: Variant
        size?: Size
        css?: CSSProp
        value?: number
        slot?: string
    }

export const CircularProgress = forwardRef<HTMLElement, Props>(
    ({ variant, value, css, size: sizeProp = 'small', ...props }, ref) => {
        const { backgroundColor, size, ...slotProps } = useSlotProps<BoxProps & { size: Size }>(
            { ...props, size: sizeProp },
            'circularProgress',
        )
        const theme = useTheme()
        const isIndeterminate = value === undefined
        const actualValue = value ? Math.max(Math.min(value, 100), 0) : 25

        const strokeWidth = parseCSSValueToNumber(theme.space[strokeWidthMap[size]])

        const radius = 100 / 2
        const actualRadius = radius - strokeWidth / 2
        const circumference = actualRadius * 2 * Math.PI
        const offset = circumference - (actualValue / 100) * circumference + strokeWidth / 2

        const keyFrameClassIndeterminate = keyframes(indeterminate)
        const strokeColorToken = isColorToken(backgroundColor) ? backgroundColor : 'backgroundEmphasis'

        return (
            <Box // @ts-expect-error Remove after #1528 is merged.
                xmlns="http://www.w3.org/2000/svg"
                as="svg"
                ref={ref}
                viewBox="0 0 100 100"
                fill="transparent"
                color={variant ? `${variant}Active` : 'brand'}
                {...defaultPadding}
                height={iconSizeMap[size]}
                width={iconSizeMap[size]}
                css={[
                    css,
                    {
                        boxSizing: 'border-box',
                        '& > *': {
                            content: '""',
                            ...(isIndeterminate
                                ? {
                                      animation: `${keyFrameClassIndeterminate} ${theme.duration.slowest} ${theme.timingFunction.linear} infinite`,
                                  }
                                : {
                                      transition: `stroke-dashoffset ${theme.duration.slow} ${theme.timingFunction.linear} 0s`,
                                  }),
                            transform: 'rotate(-90deg)',
                            transformOrigin: '50% 50%',
                        },
                    },
                ]}
                {...slotProps}
            >
                <circle
                    r={actualRadius}
                    cx={radius}
                    cy={radius}
                    strokeWidth={strokeWidth}
                    stroke={theme.colors[strokeColorToken]}
                />
                <circle
                    r={actualRadius}
                    cx={radius}
                    cy={radius}
                    strokeLinecap="round"
                    strokeWidth={strokeWidth}
                    stroke="currentcolor"
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                />
            </Box>
        )
    },
)

CircularProgress.displayName = 'CircularProgress'
