import type { ComponentProps, ComponentType, ReactNode } from 'react'
import { Suspense, memo } from 'react'

import { mutationMetaSchema } from '@adverity/contracts'
import { BaseProvider as DesignSystemBaseProvider } from '@adverity/design-system/base-provider'
import { Box } from '@adverity/design-system/components/box'
import { DefaultLoader, ModalDialogLoader } from '@adverity/design-system/patterns/loaders'
import { renderToString as dsRenderToString } from '@adverity/design-system/utils'
import { ModalDialogErrorBoundary } from '@adverity/error/error-boundary'
import type { MutationMeta, Query } from '@tanstack/react-query'
import { MutationCache, QueryClient, QueryClientProvider, matchQuery } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'

import { retryWhenServerError } from '../api'

type RenderProps = {
    component: ReactNode
    container: HTMLElement
    wrapperProps?: ComponentProps<typeof DesignSystemBaseProvider>['themeProvideWrapperProps']
    renderToastProvider?: boolean
}

type PropsComparator<TComponent extends ComponentType> = (
    prevProps: Readonly<ComponentProps<TComponent>>,
    nextProps: Readonly<ComponentProps<TComponent>>,
) => boolean

const getInvalidations = (mutationMeta: MutationMeta | undefined) => {
    const meta = mutationMetaSchema.safeParse(mutationMeta)

    if (meta.success) {
        return meta.data.invalidates === 'none'
            ? (['none', 'none'] as const)
            : [meta.data.invalidates?.void ?? 'all', meta.data.invalidates?.await ?? 'none']
    }

    // per default, void invalidate all queries and await nothing
    return ['all', 'none'] as const
}

/** @public used in test */
export const createQueryClient = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: retryWhenServerError,
            },
        },
        mutationCache: new MutationCache({
            // eslint-disable-next-line max-params
            onSettled: async (_data, _error, _variables, _context, mutation) => {
                const [voidQueries, awaitQueries] = getInvalidations(mutation.meta)

                // all means all queries that don't have staleTime: Number.POSITIVE_INFINITY set
                const allCondition = (query: Query) => {
                    const defaultStaleTime = queryClient.getQueryDefaults(query.queryKey).staleTime ?? 0
                    const staleTimes = query.observers
                        .map((observer) =>
                            typeof observer.options.staleTime === 'function'
                                ? observer.options.staleTime(query)
                                : observer.options.staleTime,
                        )
                        .filter((staleTime) => staleTime !== undefined)

                    const staleTime =
                        query.getObserversCount() > 0 && staleTimes.length > 0
                            ? Math.min(...staleTimes)
                            : defaultStaleTime

                    return staleTime !== Number.POSITIVE_INFINITY
                }

                const predicate = (queries: typeof voidQueries) => (query: Query) => {
                    if (queries === 'none') {
                        return false
                    }

                    if (queries === 'all') {
                        return allCondition(query)
                    }

                    return queries.some((queryKey) => matchQuery({ queryKey }, query))
                }

                void queryClient.invalidateQueries({
                    predicate: predicate(voidQueries),
                })

                await queryClient.invalidateQueries(
                    {
                        predicate: predicate(awaitQueries),
                    },
                    // don't trigger another fetch for queries that are already being invalidated from voidQueries
                    { cancelRefetch: false },
                )
            },
        }),
    })

    return queryClient
}

export const render = ({ component, container, wrapperProps, renderToastProvider = false }: RenderProps) => {
    createRoot(container).render(
        <QueryClientProvider client={createQueryClient()}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Suspense
                fallback={
                    <Box width="100%" height="100%">
                        <DefaultLoader aria-live="assertive" role="alert" flexGrow={1} />
                    </Box>
                }
            >
                <DesignSystemBaseProvider
                    locale={navigator.language}
                    weekStartsOn={1}
                    themeProvideWrapperProps={wrapperProps}
                    renderToastProvider={renderToastProvider}
                    dialogProviderWrapper={(props) => (
                        <Suspense fallback={<ModalDialogLoader onClose={props.onClose} />}>
                            <ModalDialogErrorBoundary {...props} />
                        </Suspense>
                    )}
                >
                    {component}
                </DesignSystemBaseProvider>
            </Suspense>
        </QueryClientProvider>,
    )
}

export const renderToString = (tree: ReactNode) =>
    dsRenderToString(
        <DesignSystemBaseProvider locale={navigator.language} renderToastProvider={false}>
            {tree}
        </DesignSystemBaseProvider>,
    )

// The solution is based on the github comment by @pie6k
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-699521381
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const componentMemo = <TComponent extends ComponentType<any>>(
    Component: TComponent,
    propsComparator?: PropsComparator<TComponent>,
) => memo(Component, propsComparator) as unknown as TComponent
