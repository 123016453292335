import { forwardRef } from 'react'

import type { TLengthStyledSystem } from 'styled-system'
import { system } from 'styled-system'

import { addCSSUnit } from '~/utils/css-unit'
import { useTheme } from '~/utils/use-theme'

import type { ResponsiveValue } from '../../system/styled-system-re-exported-types'
import type { SpaceAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import type { Orientation } from '../../types'
import type { Props as BoxProps } from '../box/box'
import { Flex } from '../flex/flex'

export type Props = {
    /**
     * Sets the space around the children elements of Stack. It implements the native `gap` behavior. See https://developer.mozilla.org/en-US/docs/Web/CSS/gap for more information.
     */
    spacing?: ResponsiveValue<SpaceAccessPaths>
    /**
     * Sets the direction of the elements. When it's vertical, the children elements will be rendered after each other, while in the case of horizontal
     * orientation, the elements will be rendered next to each other.
     */
    orientation?: ResponsiveValue<Orientation>
    /**
     * Whether items are forced onto one line or can wrap onto multiple lines.
     */
    wrap?: ResponsiveValue<boolean>
} & Omit<BoxProps, 'flexWrap' | 'wrap' | 'flexDirection'>

export const Stack = forwardRef<HTMLElement, Props>(
    ({ spacing = 'medium', orientation = 'vertical', wrap, children, css, ...props }, ref) => {
        const theme = useTheme()
        /**
         * Using `&&` as property means we inject styles to the root element as an object.
         * By using double && we increase specificity of this CSS selector.
         */
        const property = '&&' as never

        return (
            <Flex
                ref={ref}
                css={[
                    // We need to use different system function calls for each responsive props,
                    // because we use `property: "&&"` and the objects will overwrite each other otherwise
                    system({
                        spacing: {
                            property,
                            scale: 'space',
                            transform: (value: TLengthStyledSystem, scale) => ({
                                gap: addCSSUnit(value, scale as Array<TLengthStyledSystem>),
                            }),
                        },
                    })({ theme, spacing }),
                    system({
                        orientation: {
                            property,
                            transform: (value: Orientation) => ({
                                flexDirection: value === 'vertical' ? 'column' : 'row',
                            }),
                        },
                    })({ theme, orientation }),
                    system({
                        wrap: {
                            property,
                            transform: (value: boolean) => ({
                                flexWrap: value ? 'wrap' : 'nowrap',
                            }),
                        },
                    })({ theme, wrap }),
                    css,
                ]}
                {...props}
            >
                {children}
            </Flex>
        )
    },
)

Stack.displayName = 'Stack'
