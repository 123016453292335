import type { CssGlobals } from '~/utils/css-unit'
import { px } from '~/utils/css-unit'

export type BorderStyle =
    | CssGlobals
    | `${number}px`
    | 'dashed'
    | 'dotted'
    | 'double'
    | 'groove'
    | 'hidden'
    | 'inset'
    | 'none'
    | 'outset'
    | 'ridge'
    | 'solid'

const definition = {
    none: px(0),
    thin: px(1),
    thick: px(2),
} satisfies Record<string, BorderStyle>

export const borders: { [K in keyof typeof definition]: BorderStyle } = definition
