import type { ComponentPropsWithRef } from 'react'
import { forwardRef } from 'react'

import { useSeparator } from 'reakit/Separator'

import { useReakitHook } from '~/utils/use-reakit-hook'

import type { Orientation } from '../../types'
import { BorderBox } from '../border-box/border-box'

const sizeMap = {
    small: 'thin',
    medium: 'thick',
} as const

type Props = ComponentPropsWithRef<typeof BorderBox> & {
    orientation?: Orientation
    size?: 'small' | 'medium'
}

export const Separator = forwardRef<HTMLElement, Props>(
    ({ orientation = 'horizontal', size = 'small', ...props }, ref) => (
        <BorderBox
            as="hr"
            color="border"
            borderColor="current"
            borderWidth="none"
            marginX="none"
            {...(orientation === 'vertical'
                ? {
                      width: 0,
                      height: 'auto',
                      minHeight: '100%',
                      borderLeftWidth: sizeMap[size],
                  }
                : {
                      width: '100%',
                      height: '0px',
                      borderBottomWidth: sizeMap[size],
                  })}
            // eslint-disable-next-line react-compiler/react-compiler
            {...useReakitHook(useSeparator, { ref, ...props })}
        />
    ),
)

Separator.displayName = 'Separator'
