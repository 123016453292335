import { forwardRef } from 'react'

import { getGradientStyles, useWrapperRef } from '~/utils/use-gradient-wrapper'
import { useTheme } from '~/utils/use-theme'

import type { CSSProp } from '../../types'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = BoxProps & {
    scrollableContent?: boolean
}
export const DialogBody = forwardRef<HTMLElement, Props>(
    ({ children, css, scrollableContent = false, padding = 'medium', ...props }, propsRef) => {
        const theme = useTheme()
        const scrollableContentStyles: CSSProp = {
            overflowX: scrollableContent ? 'hidden' : undefined,
            overflowY: scrollableContent ? 'auto' : undefined,
        }
        const { isTopShadowVisible, isBottomShadowVisible, wrapperRef } = useWrapperRef(propsRef)

        return (
            <Box position="relative" display="contents" overflow="visible">
                {scrollableContent && (
                    <Box
                        css={getGradientStyles(theme.colors.backgroundEmphasis, 'top')}
                        opacity={isTopShadowVisible ? 1 : 0}
                    />
                )}
                <Box
                    ref={wrapperRef}
                    padding={padding}
                    backgroundColor="backgroundContent"
                    css={[scrollableContentStyles, css, scrollableContent ? {} : { height: '100%' }]}
                    {...props}
                >
                    {children}
                </Box>
                {scrollableContent && (
                    <Box
                        css={getGradientStyles(theme.colors.backgroundEmphasis, 'bottom')}
                        opacity={isBottomShadowVisible ? 1 : 0}
                    />
                )}
            </Box>
        )
    },
)

DialogBody.displayName = 'DialogBody'
