import { useCallback, useState } from 'react'

export const useControlled = <T>(
    controlledProp: T | undefined,
    defaultState: T,
    componentName: string,
): [T, (state: T) => void] => {
    const isControlled = controlledProp !== undefined
    const [state, setState] = useState(defaultState)
    // eslint-disable-next-line react/hook-use-state, @eslint-react/naming-convention/use-state
    const initialControlled = useState(isControlled)[0]

    const outputState = isControlled ? (controlledProp ?? defaultState) : state
    const setStateIfUncontrolled = useCallback(
        (value: T) => {
            if (!isControlled) {
                setState(value)
            }
        },
        [isControlled],
    )

    /*
     * Log an error to the console when the component
     * changes from uncontrolled to controlled or vice versa.
     */

    if (process.env.NODE_ENV !== 'production') {
        const controlledChanged = initialControlled !== isControlled

        if (controlledChanged) {
            // eslint-disable-next-line no-console
            console.error(
                `${componentName} changed from ${initialControlled ? 'controlled' : 'uncontrolled'} to ${
                    isControlled ? 'uncontrolled' : 'controlled'
                } version. It should not switch between those after initialization.`,
            )
        }
    }

    return [outputState, setStateIfUncontrolled]
}
