import { pipe } from 'remeda'

export const remove = <T>(array: ReadonlyArray<T>, index: number): Array<T> =>
    index < 0 || index > array.length - 1 ? [...array] : [...array.slice(0, index), ...array.slice(index + 1)]

// Source: https://stackoverflow.com/a/53187807
/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 *
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export const findLastIndex = <T>(
    array: Array<T>,
    predicate: (value: T, index: number, obj: Array<T>) => boolean,
): number => {
    let arrayLength = array.length

    // eslint-disable-next-line no-plusplus
    while (arrayLength--) {
        if (predicate(array[arrayLength] as T, arrayLength, array)) {
            return arrayLength
        }
    }

    return -1
}

export type NonEmptyArray<T> = readonly [T, ...ReadonlyArray<T>]

export const isNonEmpty = <T>(array: ReadonlyArray<T> | undefined): array is NonEmptyArray<T> =>
    !!array && array.length > 0

export const move = <T>(array: ReadonlyArray<T>, fromIndex: number, toIndex: number): Array<T> => {
    const item = array[fromIndex]

    if (item !== undefined) {
        return pipe(
            array,
            (arr) => arr.filter((_, index) => index !== fromIndex),
            (arr) => [...arr.slice(0, toIndex), item, ...arr.slice(toIndex)],
        )
    }

    return [...array]
}

export const truncateItems = <T>(items: ReadonlyArray<T>, maxVisibleItems: number | undefined): ReadonlyArray<T> =>
    maxVisibleItems && items.length > maxVisibleItems
        ? [...items.slice(0, maxVisibleItems - 2), ...items.slice(-1)]
        : items
