import type { ReactNode } from 'react'
import { createContext, useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StoryContextType = Record<string, any>

type Props = {
    storyContext: StoryContextType
    children: ReactNode
}

export const StoryContext = createContext<StoryContextType>({})

/** @public used in storybook */
export const StoryContextProvider = ({ storyContext, children }: Props) => {
    const memoizedContext = useMemo(() => storyContext, [storyContext])

    return <StoryContext.Provider value={memoizedContext}>{children}</StoryContext.Provider>
}
