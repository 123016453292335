import type { ComponentProps, FC, JSXElementConstructor, ReactElement } from 'react'
import { isValidElement } from 'react'

import type { IconStaticProps } from '~/components/icons/shared'

export const isComponentInstance = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TElement extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
>(
    node: unknown,
    expected: TElement,
): node is ReactElement<ComponentProps<TElement>> => isValidElement(node) && node.type === expected

export const isIcon = (element: ReactElement): element is ReactElement<IconStaticProps> =>
    (element.type as FC).displayName?.startsWith('Icon') ?? false
