import { system } from 'styled-system'

import type { CssGlobals, PixelValue } from '~/utils/css-unit'

import type { SpaceAccessPathsWithNegatives } from '../../theme/tokens/generated/token-access-paths'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

type Positioning = CssGlobals | SpaceAccessPathsWithNegatives | number | PixelValue | `${number}%` | 'auto'
type Position = CssGlobals | 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky'

export type PositionProps = {
    zIndex?: ResponsiveValue<CssGlobals | 'auto' | number>
    top?: ResponsiveValue<Positioning>
    right?: ResponsiveValue<Positioning>
    bottom?: ResponsiveValue<Positioning>
    left?: ResponsiveValue<Positioning>
    position?: ResponsiveValue<Position>
}

/** @public used in docs */
export const positionConfig = {
    position: true,
    zIndex: {
        property: 'zIndex',
        scale: 'zIndices',
    },
    top: {
        property: 'top',
        scale: 'space',
    },
    right: {
        property: 'right',
        scale: 'space',
    },
    bottom: {
        property: 'bottom',
        scale: 'space',
    },
    left: {
        property: 'left',
        scale: 'space',
    },
} as const satisfies Config<keyof PositionProps>

export const position = system(positionConfig)
