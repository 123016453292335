import type { ForwardedRef } from 'react'
import { useCallback, useRef, useState } from 'react'

import { useMergeRefs } from '@floating-ui/react'
import { debounce } from 'throttle-debounce'

import { useShouldActionActivatedInStory } from '~/utils/use-should-action-activated-in-story'

import type { CSSProp } from '../types'
import { setAlpha } from './color'
import { px } from './css-unit'

const GRADIENT_SIZE = 40
const GRADIENT_ANGLE_MAP = {
    top: '0',
    bottom: '-180',
} as const

export const getGradientStyles = (backgroundColor: string, direction: 'top' | 'bottom'): CSSProp => {
    const offsetX = 0
    const offsetY = 0

    return {
        position: 'sticky',
        [direction]: '-1px',
        pointerEvents: 'none',
        zIndex: 7,
        transition: 'opacity 0.195s ease-in',
        mixBlendMode: 'multiply',

        '&::before': {
            content: '""',
            display: 'block',
            height: px(GRADIENT_SIZE),
            background: `linear-gradient(${GRADIENT_ANGLE_MAP[direction]}deg, ${setAlpha(
                backgroundColor,
                0,
            ).toHex()} 0%, ${backgroundColor} 100%)`,
            position: 'absolute',
            [direction]: offsetY,
            left: offsetX,
            right: offsetX,
        },
    }
}

export const useWrapperRef = (propsRef?: ForwardedRef<HTMLElement>) => {
    const isContentScrolledInStory = useShouldActionActivatedInStory()

    const [offsetScroll, setOffsetScroll] = useState(0)
    const [scrollSize, setScrollSize] = useState(0)

    const handleContentScroll = (event: Event) => {
        if (event.target) {
            setOffsetScroll((event.target as Element).scrollTop)
        }
    }

    const scrollOffsetUpdate = (element: Element | null, updateTopScroll?: boolean) => {
        if (element) {
            const scrollSizeFromNode = element.scrollHeight - element.clientHeight

            if (updateTopScroll) {
                // eslint-disable-next-line functional/immutable-data
                element.scrollTop = scrollSizeFromNode
            }

            setScrollSize(scrollSizeFromNode)
        }
    }

    // eslint-disable-next-line react/hook-use-state, @eslint-react/naming-convention/use-state
    const [contentWrapperObserver] = useState(
        () =>
            new ResizeObserver(
                debounce<ResizeObserverCallback>(20, ([entry]) => {
                    if (entry?.target) {
                        scrollOffsetUpdate(entry.target)
                    }
                }),
            ),
    )

    const containerRef = useRef<HTMLDivElement | null>(null)

    // eslint-disable-next-line react/hook-use-state, @eslint-react/naming-convention/use-state
    const [contentMutationObserver] = useState(
        () =>
            new MutationObserver(
                debounce<MutationCallback>(20, (mutationsList) => {
                    mutationsList.forEach((mutation) => {
                        if (mutation.type === 'childList') {
                            scrollOffsetUpdate(containerRef.current)
                        }
                    })
                }),
            ),
    )

    const callbackRef = useCallback(
        (node: Element | null) => {
            if (node !== null) {
                node.addEventListener('scroll', debounce(20, handleContentScroll))
                contentWrapperObserver.observe(node)
                contentMutationObserver.observe(node, { childList: true, subtree: true })

                setTimeout(() => {
                    scrollOffsetUpdate(node)
                    setOffsetScroll(node.scrollTop)
                }, 10)

                if (isContentScrolledInStory) {
                    scrollOffsetUpdate(node, true)
                }
            }

            if (node === null) {
                contentWrapperObserver.disconnect()
                contentMutationObserver.disconnect()
            }
        },
        [contentWrapperObserver, contentMutationObserver, isContentScrolledInStory],
    )

    return {
        scrollSize,
        offsetScroll,
        wrapperRef: useMergeRefs([propsRef, callbackRef, containerRef]),
        isTopShadowVisible: offsetScroll > 0,
        // the -2 correction in scrollSize is necessary for some displays
        // probably related to the 90vh in modalbox size
        isBottomShadowVisible: scrollSize > 0 && offsetScroll < scrollSize - 2,
    }
}
