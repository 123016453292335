import type { PixelValue } from '~/utils/css-unit'
import { px } from '~/utils/css-unit'

import { grid } from './grid'

const definition = {
    'xxx-small': px(grid * 2),
    'xx-small': px(grid * 2),
    'x-small': px(grid * 2),
    small: px(grid * 2),
    medium: px(grid * 3),
    large: px(grid * 3),
    'x-large': px(grid * 4),
    'xx-large': px(grid * 6),
    'xxx-large': px(grid * 10),
} satisfies Record<string, PixelValue>

export const lineHeights: { [K in keyof typeof definition]: PixelValue } = definition
