import { forwardRef, useMemo } from 'react'

import { useGroup } from 'reakit/Group'

import { useReakitHook } from '~/utils/use-reakit-hook'
import { useTheme } from '~/utils/use-theme'

import { GroupContext } from './group-context'
import type { LimitedSize, Orientation } from '../../types'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

export type Props = BoxProps & {
    block?: boolean
    orientation?: Orientation
    size?: LimitedSize
}

const groupItemClassName = 'ads_GROUP_ITEM'

export const Group = forwardRef<HTMLElement, Props>(
    // eslint-disable-next-line complexity
    (
        {
            block = false,
            disabled = false,
            orientation = 'horizontal',
            size = 'medium',
            css,
            position = 'relative', // Make position relative for z Index 0 to be valid when wrapped in Box
            ...props
        },
        ref,
    ) => {
        const isVertical = orientation === 'vertical'
        const theme = useTheme()

        const contextValue = useMemo(
            () => ({ size, disabled, className: groupItemClassName, isInGroup: true }),
            [size, disabled],
        )

        return (
            <GroupContext.Provider value={contextValue}>
                <Box
                    display="inline-flex"
                    flexDirection={isVertical ? 'column' : 'row'}
                    width={block ? '100%' : 'auto'}
                    zIndex={0}
                    position={position}
                    css={[
                        {
                            '> *': {
                                flexGrow: block ? 1 : 0,
                                borderRadius: 0,
                            },
                            '> *:hover': { zIndex: 1 },
                            '> *:not(:first-child)': isVertical ? { marginTop: '-1px' } : { marginLeft: '-1px' },
                            [`> *:first-child,
                              > *:first-child > .${groupItemClassName}`]: {
                                borderRadius: isVertical
                                    ? `${theme.radii.medium} ${theme.radii.medium} 0 0`
                                    : `${theme.radii.medium} 0 0 ${theme.radii.medium}`,
                            },
                            [`> *:not(:first-child):not(:last-child),
                              > *:not(:first-child):not(:last-child) .${groupItemClassName}`]: {
                                borderRadius: 0,
                            },
                            [`> *:last-child,
                              > *:last-child > .${groupItemClassName}`]: {
                                borderRadius: isVertical
                                    ? `0 0 ${theme.radii.medium} ${theme.radii.medium}`
                                    : `0 ${theme.radii.medium} ${theme.radii.medium} 0`,
                            },
                            [`> *:only-child,
                            > *:only-child > .${groupItemClassName}`]: {
                                borderRadius: `${theme.radii.medium}`,
                            },
                        },
                        css,
                    ]}
                    {...useReakitHook(useGroup, { ref, ...props })}
                />
            </GroupContext.Provider>
        )
    },
)

Group.displayName = 'Group'
