import type { ComponentPropsWithRef, ReactNode } from 'react'
import { forwardRef } from 'react'

import { IconCloseLarge } from '~/icons/close-large'
import { useSlotProps } from '~/utils/components/slots'

import { headerFooterHeight } from './dialog'
import { ButtonMinimal } from '../button/button-minimal'
import { Flex } from '../flex/flex'
import { getIconButtonProps } from '../icon-button/utils'

type Props = {
    children: ReactNode
    onClose: () => void
} & ComponentPropsWithRef<typeof Flex>

export const DialogHeader = forwardRef<HTMLElement, Props>(({ onClose, children, ...props }, ref) => {
    const { id, 'aria-label': ariaLabel, ...slotProps } = useSlotProps(props, 'header')

    return (
        <Flex
            ref={ref}
            minHeight={headerFooterHeight}
            paddingX="medium"
            alignItems="center"
            justifyContent="space-between"
            css={{ whiteSpace: 'nowrap' }}
            {...slotProps}
        >
            <Flex id={id} aria-label={ariaLabel} width="100%">
                {children}
            </Flex>
            <ButtonMinimal
                {...getIconButtonProps({
                    size: 'small',
                    rounded: true,
                    'aria-label': 'Close',
                    icon: <IconCloseLarge />,
                    onClick: onClose,
                })}
            />
        </Flex>
    )
})

DialogHeader.displayName = 'DialogHeader'
