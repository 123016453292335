import { forwardRef } from 'react'

import { useTheme } from '~/utils/use-theme'

import type { GridCustomProps } from '../../system/styles/grid'
import { gridCustom } from '../../system/styles/grid'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = BoxProps & GridCustomProps

export const Grid = forwardRef<HTMLElement, Props>(({ css, ...props }, ref) => {
    const theme = useTheme()

    return (
        <Box
            css={[
                gridCustom({
                    theme,
                    ...props,
                }),
                css,
            ]}
            ref={ref}
            display="grid"
            omitProps={['columnsCount', 'minColumnWidth']}
            gridGap="x-small"
            {...props}
        />
    )
})

Grid.displayName = 'Grid'
