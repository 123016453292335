import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconCheckBox = forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            d="M9.429 18.397 3 11.97l1.813-1.813 4.616 4.603L19.187 5 21 6.826z"
        />
    </Icon>
));
IconCheckBox.displayName = "IconCheckBox";
